import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('../playlist'));

const routes: Route = {
  name: id.musicLibraryPlaylist,
  path: '/{lang?}/music-library/playlist/{categoryName}',
  searchParams: ['order:list(asc|desc|genre|duration)', 'search', 'page', 'categories', 'duration', 'subcategories'],
  element: <AsyncPage />,
};

export default routes;

import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('../../playlist/playlist'));

const routes: Route = {
  name: id.musicLibraryPlaylistSearch,
  path: '/{lang?}/music-library/search',
  searchParams: ['order:list(asc|desc|genre|duration)', 'search', 'page', 'categories', 'duration', 'subcategories'],
  element: <AsyncPage />,
};

export default routes;
